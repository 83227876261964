
import { defineComponent } from "vue";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import { IntegerColumnConfig } from "@/components/controls/catalogueGrid/columns/integerColumnConfig";
import { requiredRule } from "@/components/controls/catalogueGrid/columns/baseValidationRules";
import CatalogueGrid from "@/components/controls/catalogueGrid/CatalogueGrid.vue";
import featureCreateEditDialog from "./FeatureCreateEditDialog.vue";
import featureInfoDialog from "@/components/info/FeatureInfoDialog.vue";

export default defineComponent({
  components: {
    CatalogueGrid,
  },
  props: {
    //показатель режима выбора
    selectMode: { required: false, type: Boolean, default: false },
    gridHeight: { required: false, type: String, default: null },
    //функция для получения выбранных элементов
    getSelectedData: { type: Function },
    //функция для вызова при изменении выбранных элементов
    changeSelectedData: { type: Function },
  },
  computed: {
    featureCreateEditDialog(): any {
      return featureCreateEditDialog;
    },
    featureInfoDialog(): any {
      return featureInfoDialog;
    },
  },
  data() {
    return {
      editingConfig: new TableEditingConfig({
        allowUpdating: true,
        allowAdding: true,
        allowDeleting: true,
        confirmDelete: true,
        allowDataFill: false,
        allowClearFilter: true,
        allowDeleteMultipleRows: false,
        showInfo: true,
      }),
      selectModeEditingConfig: new TableEditingConfig({
        allowUpdating: false,
        allowAdding: false,
        allowDeleting: false,
        confirmDelete: false,
        allowDataFill: false,
        allowExport: false,
        allowDeleteMultipleRows: false,
      }),
      columns: [
        new IntegerColumnConfig({
          dataField: "id",
          caption: "Код",
          readOnly: true,
          editVisible: false,
          format: ""
        }),
        new TextColumnConfig({
          dataField: "name",
          caption: "Наименование",
          validationRules: [requiredRule],
        }),
      ],
      designConfig: new TableDesignConfig({
        popUpColumnsCount: 1,
        popUpWidth: "850px",
        pagingMode: "scroll",
        heightScroll: !this.selectMode ? "calc(100vh - 205px)" : "400px",
        horizontalScroll: true,
      }),
      selectionConfig: new TableSelectionConfig({
        mode: "single",
      }),
      pageSizes: [10, 20, 50, 100],
    };
  },
  created() {
    if (this.selectMode) {
      this.editingConfig = this.selectModeEditingConfig;
    }
    if (this.gridHeight) {
      this.designConfig.height = this.gridHeight;
    }
  },
});
